<template>
  <v-dialog
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    scrollable
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{

          editMode ? "تعديل البوستر الاعلانى" : "اضافة بوستر اعلانى جديد"

        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            
<!--            <v-autocomplete-->
<!--              dense-->
<!--              outlined-->
<!--              hide-details-->
<!--              clearable-->
<!--              label="القسم"-->
<!--              prepend-icon="mdi-sitemap"-->
<!--              class="mb-5"-->
<!--              item-text="section"-->
<!--              item-value="id"-->
<!--              v-model="form.section_id"-->
<!--              :error-messages="errors.section_id"-->
<!--              :items="sections"-->
<!--            ></v-autocomplete>-->

<!--            <v-autocomplete-->
<!--              dense-->
<!--              outlined-->
<!--              hide-details-->
<!--              clearable-->
<!--              label="البلد"-->
<!--              prepend-icon="mdi-sitemap"-->
<!--              class="mb-5"-->
<!--              item-text="country"-->
<!--              item-value="id"-->
<!--              v-model="form.country_id"-->
<!--              :error-messages="errors.country_id"-->
<!--              :items="countries"-->
<!--            ></v-autocomplete>-->

            <!-- Date Inputs -->
            <div class="mb-5">
              <label>من تاريخ</label>
              <input type="datetime-local" v-model="form.start_date" :error-messages="errors.start_date" required/>
              <div v-if="errors.start_date" class="mt-2">
                <span color="error">{{ errors.start_date }}</span>
              </div>

              <!-- Add vertical spacing between date inputs -->
              <div class="mb-3"></div>

              <label class="mt-3">الى تاريخ</label>
              <input type="datetime-local" v-model="form.end_date" :error-messages="errors.end_date" required/>
              <div v-if="errors.end_date" class="mt-2">
                <span color="error">{{ errors.end_date }}</span>
              </div>
            </div>


            <div class="mb-5">
              <v-autocomplete
                  dense
                  outlined
                  hide-details
                  clearable
                  label="نوع الملف"
                  prepend-icon="mdi-sitemap"
                  class="mb-3"
                  item-text="name"
                  item-value="name"
                  v-model="form.file_type"
                  :error-messages="errors.file_type"
                  :items="files"
              ></v-autocomplete>

              <!-- File Input Group -->
              <v-row>
                <v-col cols="6" sm="6">
                  <v-file-input
                      v-if="form.file_type == 'image'"
                      dense
                      outlined
                      label="الصورة [Ar]"
                      prepend-icon="mdi-image"
                      accept="image/*"
                      v-model="form.file"
                      :error-messages="errors.file"
                  ></v-file-input>
                  <v-file-input
                      v-else
                      dense
                      outlined
                      label="video [Ar]"
                      prepend-icon="mdi-video"
                      accept="الفيديو/*"
                      v-model="form.file"
                      :error-messages="errors.file"
                  ></v-file-input>



                     <v-file-input
                      v-if="form.file_type == 'image'"
                      dense
                      outlined
                      label="الصورة [En]"
                      prepend-icon="mdi-image"
                      accept="image/*"
                      v-model="form.file_en"
                      :error-messages="errors.file_en"
                  ></v-file-input>

                  <v-file-input
                      v-else
                      dense
                      outlined
                      label="video [En]"
                      prepend-icon="mdi-video"
                      accept="الفيديو/*"
                      v-model="form.file_en"
                      :error-messages="errors.file_en"
                  ></v-file-input>


                </v-col>

                <v-col cols="6">
                  <!-- Error Messages -->
                  <p v-if="form.file_type == 'image'" class="mt-0 error_class">*accept JPJ SIZES 1200*600 AND LESS</p>
                  <p v-else class="mt-0 error_class">*accept MP4 </p>
                </v-col>
              </v-row>


            </div>


            <v-text-field
                dense
                v-model="form.phone"
                label="الهاتف"
                outlined
                placeholder="* ادخل رقم الهاتف مسبوق بكود الدولة"
            ></v-text-field>

            <v-text-field
                dense
                v-model="form.whatsapp"
                label="الواتس"
                outlined
                placeholder="* ادخل رقم whatsapp مسبوق بكود الدولة"
            ></v-text-field>



              <v-text-field dense v-model="form.title" label="عنوان الإعلان" outlined></v-text-field>




          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="formLoading" color="secondary" elevation="1" dark @click="editMode ? update(form) : create(form)">حفظ</v-btn>
        <v-btn @click="HIDE_FORM();">إلغاء</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations , mapActions } from "vuex";

export default {
  name: "record-form",

  data: function() {
    return {
      form: {},
      errors: {},
      formLoading: false,
      // sections: [],
      countries: [],
      files: [
        {name : "image"},
        {name: "video"}
      ],
    };
  },
  created(){
      this.fetchCountries();
      // this.fetchSections();
  },
  computed: {
    ...mapState("sliders", {
      editMode: state => state.editMode,
      showForm: state => state.showForm,
      formRecord: state => {
        return {
          id: state.formRecord.id,
          // country_id: state.formRecord.country_id,
          file_type: state.formRecord.file_type,
          start_date: state.formRecord.start_date,
          end_date: state.formRecord.end_date,

          title: state.formRecord.title,
          phone : state.formRecord.phone,
          whatsapp : state.formRecord.whatsapp,
          // file: state.formRecord.file,
          // icon: state.formRecord.icon
        };
      }
    })
  },

  watch: {
    formRecord(form) {
      this.form = form;
    }
  },

  methods: {
    ...mapMutations("sliders", ["HIDE_FORM"]),
    ...mapActions("sliders", ["fetchRecords"]),
    // fetchSections(){
    //   this.axios.get("/admin/sliders/section_dropdown").then((response) =>{
    //     this.sections = response.data;
    //   });
    // },

    fetchCountries()
    {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
        this.countries = response.data;
        // // console.log(this.files);
      });
    },
    create(form) {
      this.formLoading = true;
      // form.section_id = this.item.id;
      // // console.log(form.section_id);
      // console.log(form);
      this.$store
        .dispatch("sliders/create", { form })
        .then(response => {
          this.$bus.$emit("showSnackbar", {
            text: "تمت اضافة التصنيف بنجاح",
            color: "success"
          });
          this.HIDE_FORM();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "خطا اثناء اضافة التصنيف",
            color: "error"
          });
          this.HIDE_FORM();
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },

    update(form) {
      this.formLoading = true;
      // console.log({ form });
      this.$store
        .dispatch("sliders/update", { form })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: "Data updated",
            color: "success"
          });
          this.HIDE_FORM();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when updating the category.",
            color: "error"
          });
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    }
  }
};
</script>
