<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3">
      <v-icon class="ml-1">mdi-filter</v-icon>التصنيفات
    </h3>
    <v-row align="center">
      <!-- QUERY -->

      <!-- Q -->
<!--      <v-col class="d-flex" cols="12" sm="6">-->

<!--        <v-autocomplete-->
<!--          rounded-->
<!--          outlined-->
<!--          hide-details-->
<!--          clearable-->
<!--          solo-->
<!--          label="الدولة"-->
<!--          class="mb-5"-->
<!--          item-text="country"-->
<!--          item-value="id"-->
<!--          v-model="filters.country_id"-->
<!--          :items="countries"-->
<!--        ></v-autocomplete>-->
<!--      </v-col>-->

      <v-col class="d-flex" cols="12" sm="6">
        <v-text-field
            rounded
            outlined
            solo
            hide-details
            clearable
            label="ابحث بالاسم   "
            v-model="filters.title"
        ></v-text-field>
      </v-col>

      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="6">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { ...filters, page: 1 , active:1} })"
          large
          color="primary"
          >بحث</v-btn
        >

        <v-btn class="mr-2" @click="$router.replace({ query: { page: 1, active:0} })" large color="primary"
          >عرض الكل
        </v-btn>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1, active:1 } })"
          large
          color="primary"
        >
          عرض النشط
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "filters-box",

  data: function () {
    return {
      categoriesLoading: false,
      // section: "",
      date: "",
      filters: {},
      countries: [],
    };
  },

  created() {
      // this.fetchCountry();
      // this.fetchSections();
  },
  computed: {
    ...mapState({
      // sections: (state) => state.filters.sections,
    }),
  },
  methods: {
    // ...mapActions("filters", ["fetchSections"]),
    ...mapActions("sliders", ["fetchRecords"]),
    ...mapMutations("sliders", ["SET_FILTERS"]),
    // fetchCountry() {
    //   this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
    //     this.countries = response.data;
    //   });
    // },
  },
};
</script>
